export enum Pathways {
    GettingMastersInABA = 1,
    ForeignDegreeDoingAbaCoursework,
    FulltimeFacultyTeachingAndResearch,
    PostDoctorialExperience,
    Unknown = 42
}

export enum OnboardingStep {
    SupervisorOrTrainee = 0,
    Pathway,
    AddSupervisor,
    DegreeRequirements
}

export enum Roles {
    Trainee = 1,
    Supervisor,
    Unknown = 42
}

export enum Qualifications{
  BCBAD = 1,
  BCBA,
  Unknown = 42
}

export const QualificationStrings = ['Unknown', 'BCBA-D', 'BCBA']