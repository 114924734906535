
import React, { useState, useEffect, useCallback } from "react"

import {
  Table,
  TableContainer, 
  TableSortLabel, 
  Box,
  Grid, 
  Input
} from '@mui/material'

import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { visuallyHidden } from '@mui/utils'
import { FieldworkEntry, FieldworkSortingType, selectFieldworkSortBy } from "../fieldworkSlice"
import { FieldworkRow } from "./FieldworkRow"


/* 
    parameters:

        data: an array of FieldworkEntry objects [FieldworkEntry[]]
        title: title of the table [string]
        subtitle: subtitle for the table, such as date being viewed [string]

    Usage:

    // sortData enables sorting for the fieldwork table based on FieldworkSortingType

        <FieldworkTable 
            data={sortData({ sortBy:sortyBy,  data:fieldworkEntries })} 
            title='My Fieldwork' 
            subtitle='Today' 
        />

*/
export const FieldworkTable = ({ data, title, subtitle }:{ data: FieldworkEntry[], title: string, subtitle: string }) => {
    
    const sortFieldworkBy = useAppSelector(selectFieldworkSortBy)

    const dispatch = useAppDispatch()

    const handleSortingChange = (
        event: React.SyntheticEvent | null,
        newValue: FieldworkSortingType | null,
      ) => {
        dispatch({ type: "fieldwork/sortBy", payload: newValue })
    };
   
    
    return(
      <div style={{filter:'drop-shadow(0px 0px 5px black)', height:'auto'}}>
        <Grid container spacing={2} direction='row' style={{paddingBottom:'4px', paddingLeft:'5px'}} >
        
            {title && 
            <Grid   className='table-legend' style={{ fontSize: '2em', paddingLeft: 'calc(5px + 1vw)' }} >
                { title}
            </Grid>
            
            }
        
            {subtitle &&
            <Grid   className='table-legend' style={{ fontSize: '1em', lineHeight:'2.5em'}}>
                {subtitle}
            </Grid>
            }
            
        </Grid>
        <TableContainer sx={{maxHeight: 'inherit'}}>

            <Table 
                stickyHeader aria-label="sticky table"
                sx={{
                    // width: 'max-content',
                    height:'100%',
                    justifyContent:'space-evenly', alignContent:'space-evenly',
                    '& tr > *:not(:first-of-type)': { textAlign: 'center'},
                    '& tr > *:first-of-type': {
                    textAlign: 'center',

                    left: 0,
                    },
                    '&thead':{
                    position:'sticky',
                    top:'0px'
                    },
                    '& thead th:nth-of-type(1)': { textAlign:'center', width:'10%' },
                    '& thead th:nth-of-type(2)': { textAlign:'center', width:'10%' },
                    '& thead th:nth-of-type(3)': { textAlign:'center', width:'10%' },
                    '& thead th:nth-of-type(4)': { textAlign:'center', width:'10%' },
                    '& thead th:nth-of-type(5)': { textAlign:'center', width:'10%' },
                    '& thead th:nth-of-type(6)': { textAlign:'center', width:'10%' },
                    '& thead th:nth-of-type(7)': { textAlign:'center', width:'10%' },
                }}
            >           
                <thead style={{ position:'sticky'}}>
                    <tr className="table-header">
                        <th className="table-header">
                            <TableSortLabel
                                sx={{
                                    marginLeft:'18px',
                                    '&.Mui-active':{
                                    color:'#23f9ff!important'
                                    },
                                    '&:hover':{
                                    color:'#23f9ff'
                                    }
                                }}
                                active={sortFieldworkBy ===  FieldworkSortingType.DateAscending || sortFieldworkBy ===  FieldworkSortingType.DateDescending}
                                direction={sortFieldworkBy === FieldworkSortingType.DateDescending ? 'desc' : 'asc'}
                                onClick={(e)=>handleSortingChange( e, sortFieldworkBy === FieldworkSortingType.DateDescending? FieldworkSortingType.DateAscending: FieldworkSortingType.DateDescending)}
                            >
                            
                                {sortFieldworkBy === FieldworkSortingType.DateAscending? (
                                <Box component="span" sx={visuallyHidden} />
                                
                                ) : null}
                                Date
                            
                            </TableSortLabel>
                        </th>

                        <th className="table-header">
                            Start Time
                        </th>

                        <th className="table-header">
                            End Time
                        </th>

                        <th className="table-header">
                            Supervision Type
                        </th>

                        <th className="table-header">
                            Setting
                        </th>

                        <th className="table-header">
                            Restricted?
                        </th>

                        <th className="table-header">
                            Supervisor
                        </th>

                    </tr>
                </thead>
                
                <tbody >
                    {data && 
                    data.map((entry:FieldworkEntry, index) => (
                        <FieldworkRow entry={entry} key={entry.id} />
                    ))}
                </tbody>
            </Table>
        </TableContainer>
      </div>
   
    )
}