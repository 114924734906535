import { ReactNode } from "react";
import {Navigate, Outlet} from 'react-router-dom'
import { useAppSelector } from "../app/hooks";
import { selectUserId } from "../features/user/userSlice";


export const ProtectedRoute = () => {
    const userId = useAppSelector(selectUserId)

    if (!userId) {
      return <Navigate to="/Login" replace />;
    }
  
    return <Outlet/>;
  };