import { 
    Dialog, 
    Grid,
    Card,
    FormControl,
    Box,
    TextField,
    Button,
} from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addFieldworkEntry, selectCurrentEntry, selectCurrentEntryId } from '../fieldworkSlice';
import dayjs, { Dayjs } from 'dayjs';
import { getTodayDate } from '@mui/x-date-pickers/internals';



export const AddFieldworkModal = Demodal.create(
    (
        
    ) => {

    const dispatch = useAppDispatch()
    const addFieldworkModal = useModal()
    const currentEntry = useAppSelector(selectCurrentEntry)
    const currentEntryId = useAppSelector(selectCurrentEntryId)


    const handleDateChange = async (date: Dayjs | null) =>{
        if (date) {
            await dispatch({
                type: 'fieldwork/setFieldworkEntryDate', 
                payload: date.toString()
            })
        }
        else {
            return
        }
    }

    const handleStartTimeChange = async (date: Dayjs | null) =>{
        if (date) {
            await dispatch({
                type: 'fieldwork/setFieldworkEntryStartTime', 
                payload: date.toString()
            })
        }
        else {
            return
        }
    }

    const handleEndTimeChange = async (date: Dayjs | null) =>{
        if (date) {
            await dispatch({
                type: 'fieldwork/setFieldworkEntryEndTime', 
                payload: date.toString()
            })
        }
        else {
            return
        }
    }

    const handleSubmitEntry = async () => {
        await dispatch(addFieldworkEntry({fieldworkEntry:currentEntry, userId:1}))
        addFieldworkModal.close()
    }

    return (
      <Dialog 
        {...muiDialog(addFieldworkModal)}
      >
        <Card style={{ padding: '10px', border: '1px solid rgba(255,255,255,0.2)', borderRadius:'0px', filter: 'drop-shadow(0px 0px 15px black)', overflowY: 'scroll', minWidth:'200px', minHeight:'200px', alignItems:'center', justifyContent:'center' }}>
            <Box sx={{ m: '2% 0' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} sx={{justifyContent:'center'}}>
                        <DatePicker label="Date"
                            defaultValue={dayjs()}
                            value={dayjs(currentEntry.date)}
                            onChange={(newValue)=>                             
                                handleDateChange(newValue)
                            }
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Box>  

            <Box sx={{ m: '2% 0' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']} sx={{justifyContent:'center'}}>
                        <TimePicker label="Start Time" 
                            defaultValue={dayjs()}
                            value={dayjs(currentEntry.startTime)}
                            onChange={(newValue)=>{
                                handleStartTimeChange(newValue)
                            }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Box>  

            <Box sx={{ m: '2% 0' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']} sx={{justifyContent:'center'}}>
                        <TimePicker label="End Time" 
                            value={dayjs(currentEntry.endTime)}
                            onChange={(newValue)=>{
                                handleEndTimeChange(newValue)
                            }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Box>  

            {/* <Box sx={{ m: '2% 0' }}>
                <TextField
                    id="outlined-controlled"
                    label="First Name"                    
                    value={supervisor.firstName}
                    onChange={(event)=>{    
                        dispatch({
                        type: 'user/setSupervisorFirstName', 
                        payload: event.target.value
                        })
                    }}
                />
            </Box>   */}
            
            
            <Button onClick={()=>{handleSubmitEntry()}}>
                Submit
            </Button>
        </Card>
      </Dialog>
    )
})

