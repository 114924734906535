import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import { authSlice } from "secureid-react"
import fieldworkReducer from "../features/fieldwork/fieldworkSlice"
import supervisorReducer from "../features/supervisor/supervisorSlice"
import userReducer from "../features/user/userSlice"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authSlice.reducer,
    fieldwork: fieldworkReducer,
    supervisor: supervisorReducer,
    user: userReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
