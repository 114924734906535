import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import axios from "axios"
import dayjs from "dayjs"
import { Dayjs } from "dayjs"




export enum FieldworkSortingType {
    DateAscending= 1,
    DateDescending,
    SettingAscending,
    SettingDescending,
    SupervisorNameAscending,
    SupervisorNameDescending,
    SupervisionTypeAscending,
    SupervisionTypeDescending,
    FieldworkTypeAscending,
    FieldworkTypeDescending
}

export enum FieldworkSupervisionType {
    Unsupervised = 0,
    Supervised,
    ConcentratedSupervised,
    Unknown = 42
}

/*
    Unrestricted:
    Hours spent doing things like office work or supervision would count as unrestricted
    60% of the fieldwork time required for licensing is unrestricted

    Restricted:
    Hours spent directly working with clients
    40% of the fieldwork time required for licensing is restricted
*/
export enum LoggedHoursType {
    Unrestricted = 1,
    Restricted,
    Unknown = 42
}

// Setting is only required if the hours are supervised
export enum SettingType {
    Home = 1,
    School,
    Office,
    Community,
    Unknown = 42
}

export interface FieldworkEntry {
    id: number,
    supervisionType: FieldworkSupervisionType,
    setting: SettingType | undefined,
    supervisorName: string,
    date: string,
    startTime: string,
    endTime: string,
    type: LoggedHoursType
}

export const defaultFieldworkEntry: FieldworkEntry = {
    id: 0,
    date: '',
    endTime: '',
    startTime: '',
    supervisorName: '',
    supervisionType: FieldworkSupervisionType.Unknown,
    setting: SettingType.Unknown,
    type: LoggedHoursType.Unknown

}

const initialState : FieldworkState = {
    entries: [],
    currentEntry: defaultFieldworkEntry,
    status: 'idle',
    currentEntryId: 0,
    sortBy: FieldworkSortingType.DateDescending
}

export  interface FieldworkState {
    entries: FieldworkEntry[],
    currentEntry: FieldworkEntry,
    status: 'idle' | 'loading' | 'failed',
    currentEntryId: number,
    sortBy: FieldworkSortingType
}



export const addFieldworkEntry = createAsyncThunk(
    "fieldwork/addEntry",
  
    async ({ userId, fieldworkEntry }:{ userId: number, fieldworkEntry: FieldworkEntry }) => {
    //   const response = await instance.request({
    //     method: 'POST',
    //     url:`/token/live`,
    //     data:{
    //       requestedSource: LaunchedTypes.All,
    //       fetchCount: NUM_LIVE_DATA_ENTRIES,
    //       id: id,
    //       nonce: nonce,
    //       chain: chain === ChainIds.Ethereum? 'eth':'solana'
    //     }
    //   })
    
        const response = {
            data: fieldworkEntry
            
        }
        return { data: response.data }
    },
)


export const removeFieldworkEntry = createAsyncThunk(
    "fieldwork/removeEntry",
  
    async ({ userId, entryId }:{ userId: number, entryId: number }) => {
    //   const response = await instance.request({
    //     method: 'POST',
    //     url:`/token/live`,
    //     data:{
    //       requestedSource: LaunchedTypes.All,
    //       fetchCount: NUM_LIVE_DATA_ENTRIES,
    //       id: id,
    //       nonce: nonce,
    //       chain: chain === ChainIds.Ethereum? 'eth':'solana'
    //     }
    //   })
    
        const response = {
            status: 200,
            data: {
                id: entryId
            }

            
        }
        return { data: response.data }
    },
)


export const fieldworkSlice = createSlice({
    name: "fieldwork",
    initialState,
    reducers:{
        sortBy: (state, action: PayloadAction<FieldworkSortingType>) => {
            state.sortBy = action.payload
        },
        setFieldworkEntryDate: (state, action: PayloadAction<string>) => {
            state.currentEntry.date = action.payload
        },
        setFieldworkEntryStartTime: (state, action: PayloadAction<string>) => {
            state.currentEntry.startTime = action.payload
        },
        setFieldworkEntryEndTime: (state, action: PayloadAction<string>) => {
            state.currentEntry.endTime = action.payload
        },        
    },
    extraReducers: (builder) => {
        builder
          .addCase(addFieldworkEntry.pending, (state) => {
            state.status = "loading"
          })
          .addCase(addFieldworkEntry.fulfilled, (state, action) => {
            state.status = "idle"
            state.entries = [{...action.payload.data, id: state.currentEntryId + 1}, ...state.entries]
            state.currentEntryId += 1;
          })
          .addCase(addFieldworkEntry.rejected, (state) => {
            state.status = "failed"
          })
          .addCase(removeFieldworkEntry.pending, (state) => {
            state.status = "loading"
          })
          .addCase(removeFieldworkEntry.fulfilled, (state, action) => {
            state.status = "idle"
            state.entries = state.entries.filter((entry)=>entry.id !== action.payload.data.id)
          })
          .addCase(removeFieldworkEntry.rejected, (state) => {
            state.status = "failed"
          })
    }

})

export const {sortBy} = fieldworkSlice.actions

export default fieldworkSlice.reducer

export const selectFieldwork = (state:RootState) => state.fieldwork.entries
export const selectStatus = (state:RootState) => state.fieldwork.status
export const selectCurrentEntry = (state:RootState) => state.fieldwork.currentEntry
export const selectCurrentEntryId = (state:RootState) => state.fieldwork.currentEntryId
export const selectFieldworkSortBy = (state:RootState) => state.fieldwork.sortBy
