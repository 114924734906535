import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, Slide, Button, Stepper, Step, StepLabel, InputLabel, Input, ThemeProvider, TextField, Select, MenuItem } from '@mui/material'
import { Box } from '@mui/system'
import React, { FormEvent, ReactElement, useEffect, useState } from 'react'
import { OnboardingStep, Pathways, QualificationStrings, Qualifications, Roles } from '../features/common/commonTypes'
import { selectPathway, selectRole, selectOnboardingSupervisor, selectIsOnboarded, submitOnboardingForm, selectUserId, selectName, selectBacbAccountId, selectCertificationNumber, selectEmail, selectDateQualifiedToSupervise, selectQualificationTypeId, selectAuthzyUserId, selectOnboardingSelections } from '../features/user/userSlice'
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Navigate, useNavigate } from 'react-router-dom'


const labels = [
    'Supervisor or Trainee',
    'Pathway', 
    'Add a Supervisor'
]

const RoleSelect = () => {
    const role = useAppSelector(selectRole)
    const dispatch = useAppDispatch()

    return (
        <Box sx={{ mx: 'auto' }}>                 
            <FormControl>
                <FormLabel 
                id="demo-radio-buttons-group-label" 
                sx={{ fontSize: '32px' }}
                >
                    I am currently a:
                </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={role}
                        onChange={(event)=>{    
                            dispatch({
                            type: 'user/setRole', 
                            payload: event.target.value
                            })
                        }}
                    >
                        <FormControlLabel value="trainee" control={<Radio />} label="Trainee" />
                        <FormControlLabel value="supervisor" control={<Radio />} label="Supervisor" />
                    </RadioGroup>            
            </FormControl>
        </Box>
    )
}

const Pathway = () => {
    const pathway = useAppSelector(selectPathway)
    const dispatch = useAppDispatch()

    return (
        <Box sx={{ mx: 'auto' }}>                 
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: '32px' }}>I choose pathway:</FormLabel>
                    <RadioGroup 
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        defaultValue={Pathways.Unknown}
                        value={pathway}
                        onChange={(event)=>{    
                            dispatch({
                            type: 'user/setPathway', 
                            payload: event.target.value
                            })
                        }}
                    >
                        <FormControlLabel value={Pathways.GettingMastersInABA} control={<Radio />} label="1: Getting a Masters in ABA" />
                        <FormControlLabel value={Pathways.ForeignDegreeDoingAbaCoursework} control={<Radio />} label="2: ABA Coursework" />
                        <FormControlLabel value={Pathways.FulltimeFacultyTeachingAndResearch} control={<Radio />} label="3: Faculty Teaching & Research" />
                        <FormControlLabel value={Pathways.PostDoctorialExperience} control={<Radio />} label="4: Post Doctorial Experience" />
                    </RadioGroup>
            </FormControl>     
        </Box>
    )
}

const AddSupervisor = () => {
    const supervisor = useAppSelector(selectOnboardingSupervisor)
    const dispatch = useAppDispatch()

    const handleSupervisionDateChange = async (date: string | null) =>{
        console.log(date)
        if (date) {
            await dispatch({
                type: 'user/setDateQualifiedToSupervise', 
                payload: dayjs( date).toDate().toLocaleDateString()
            })
        }
        else {
            return
        }
    }
    const handleDateOfSupervisionContract = async (date: string | null) =>{
        if (date) {
            await dispatch({
                type: 'user/setDateOfSupervisionContract', 
                payload: dayjs( date).toDate().toLocaleDateString()
            })
        }
        else {
            return
        }
    }

    return (
    <Box
        sx={{
            width: 500,
            maxWidth: '100%'
        }}
    >
        <FormControl>
            <Box sx={{ m: '2% 0' }}>
                <TextField
                    id="outlined-controlled"
                    label="First Name"                    
                    value={supervisor.firstName}
                    onChange={(event)=>{    
                        dispatch({
                        type: 'user/setSupervisorFirstName', 
                        payload: event.target.value
                        })
                    }}
                />
            </Box>  
            <Box sx={{ m: '2% 0' }}>
                <TextField
                    id="outlined-controlled"
                    label="Last Name"
                    value={supervisor.lastName}
                    onChange={(event)=>{    
                        dispatch({
                        type: 'user/setSupervisorLastName', 
                        payload: event.target.value
                        })
                    }}
                />
            </Box>      
            <Box sx={{ m: '2% 0' }}>
                <TextField
                    id="outlined-controlled"
                    label="BCBA Account ID"
                    value={supervisor.bcbaAccountId}
                    onChange={(event)=>{    
                        dispatch({
                        type: 'user/setSupervisorBcbaAccountId', 
                        payload: event.target.value
                        })
                    }}
                />
            </Box>  
            <Box sx={{ m: '2% 0' }}>
                <TextField
                    id="outlined-controlled"
                    label="ACB Certification #"
                    value={supervisor.acbCertificationNumber}
                    onChange={(event)=>{    
                        dispatch({
                            type: 'user/setSupervisorAcbCertNumber', 
                            payload: event.target.value
                        })
                    }}
                    />
            </Box>  
            <FormControl sx={{ m: '4% 0 0 0' }} >
                <InputLabel id="demo-simple-select-label">Qualifications</InputLabel>
                <Select 
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Qualifications"
                    value={supervisor.qualifications}
                    onChange={(event)=>{    
                        dispatch({
                            type: 'user/setQualifications', 
                            payload: event.target.value
                        })
                    }}
                >
                    <MenuItem value={Qualifications.BCBA}>{QualificationStrings[Qualifications.BCBA]}</MenuItem>
                    <MenuItem value={Qualifications.BCBAD}>{QualificationStrings[Qualifications.BCBAD]}</MenuItem>
                    <MenuItem value={Qualifications.Unknown}>{QualificationStrings[Qualifications.Unknown]}</MenuItem>
                </Select>
            </FormControl>
            <Box sx={{ m: '2% 0' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} sx={{justifyContent:'center'}}>
                        <DatePicker label="Date Qualified to Supervise" 
                        value={supervisor.dateQualifiedToSupervise}
                        onChange={(newValue)=>                             
                            handleSupervisionDateChange(newValue)
                        }
                />
                    </DemoContainer>
                </LocalizationProvider>
            </Box>  
            <Box sx={{ m: '2% 0' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} sx={{justifyContent:'center'}}>
                        <DatePicker label="Date of Supervision Contract" 
                            value={supervisor.dateOfSupervisionContract}
                            onChange={(newValue)=>{
                                console.log(newValue)
                                handleDateOfSupervisionContract(newValue)
                            }}
                            />
                    </DemoContainer>
                </LocalizationProvider>
            </Box>  
        </FormControl>
    </Box>
    )
}

function useMultiStepForm(steps: ReactElement[]) {
  const [currentStep, setCurrentStep] = useState(0);
  
  function next() {
    setCurrentStep(i => {
        if (i >= steps.length - 1) return i
        return i + 1
    })
  }

  function back() {
    setCurrentStep(i => {
        if (i <= 0) return i
        return i - 1
    })
  }

  function goto(index: number) {
    setCurrentStep(index)
  }

    return {
        currentStep,
        step: steps[currentStep],
        steps,
        goto,
        next,
        back
    }
}

function Onboarding() {

    const role = useAppSelector(selectRole)
    const pathway = useAppSelector(selectPathway)
    const supervisor = useAppSelector(selectOnboardingSupervisor)
    const authzyUserId = useAppSelector(selectAuthzyUserId)
    const userId = useAppSelector(selectUserId)
    const isOnboarded = useAppSelector(selectIsOnboarded)
    const onboardingSelections = useAppSelector(selectOnboardingSelections)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { step, steps, currentStep, next, back } = useMultiStepForm([
        <RoleSelect/>,
        <Pathway />,
        <AddSupervisor />
    ]);

    useEffect(() => {
        if(isOnboarded){
            console.log('would redirect to dashboard')
            navigate('/Dashboard')
        }
    },[isOnboarded])

    const onSubmit = async (event: FormEvent) => {    
        
        event.preventDefault()
        if(userId && authzyUserId)
            await dispatch (
                submitOnboardingForm({
                    userId: userId,
                    authzyUserId: authzyUserId,
                    onboarding: onboardingSelections,
                })
            )       
    }

    const shouldDisableButton = (currentStep: number) => {
        switch(currentStep){
            case OnboardingStep.SupervisorOrTrainee:
                if (role === Roles.Unknown){
                    return true;
                }
                else {
                    return false;
                }
            case OnboardingStep.Pathway:
                if(pathway === Pathways.Unknown){
                    return true;
                }
                else{
                    return false;
                }
            case OnboardingStep.AddSupervisor:
                if(
                    supervisor.firstName === '' ||
                    supervisor.lastName === '' ||
                    supervisor.acbCertificationNumber === '' ||
                    supervisor.bcbaAccountId === '' ||
                    supervisor.dateOfSupervisionContract === null ||
                    supervisor.dateQualifiedToSupervise === null ||
                    supervisor.qualifications === Qualifications.Unknown
                ){
                    return true;
                }
                else{
                    return false;
                }
  
        }
    }

    return (
        <form 
            onSubmit={(event) => {onSubmit(event)}} 
            style={{ margin: 'auto', marginTop: '80px', maxWidth: '750px' }}
        >     

                <Box>   
                    <Stepper activeStep={currentStep} alternativeLabel>
                    {labels.map((label) => (    
                        <Step key={label} > 
                            <StepLabel>{label}</StepLabel>                        
                        </Step> 
                    ))}
                    </Stepper>
                    <Box sx={{ mt: '55px' }}>                        
                        {step}
                        {currentStep > OnboardingStep.SupervisorOrTrainee ? 
                            <Button onClick={back} variant='contained' sx={{ width: '50px', mt: '20px', mx: '5%' }} >
                                Back
                            </Button>:
                            <></> 
                        }
                        {currentStep == OnboardingStep.AddSupervisor ? 
                            <Button 
                                variant='contained' 
                                type='submit'
                                disabled={shouldDisableButton(currentStep)}         
                                sx={{ width: '50px', mt: '20px', mx: '5%' }}
                            >
                                Submit
                            </Button>
                            : 
                            <Button 
                                onClick={next} 
                                disabled={shouldDisableButton(currentStep)} 
                                variant='contained' 
                                sx={{ width: '50px', mt: '20px', mx: '5%' }} 
                            >
                                Next
                            </Button>
                        }
                    </Box>
                </Box> 

        </form>
        )
}

export default Onboarding