import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import { AuthState } from 'secureid-react'
import { initialState as initialAuthState }  from "secureid-react"
import axios from 'axios'
import { Pathways, Qualifications, Roles } from "../common/commonTypes";
import { Supervisor } from "../supervisor/supervisorSlice";
import { CorsOrigin, ResourceServerUri } from "../../config"

const instance = axios.create();

instance.defaults.headers.common["Content-Type"] = "application/json"
instance.defaults.baseURL = `${ResourceServerUri}`
instance.defaults.headers['Access-Control-Allow-Origin'] = `${CorsOrigin}`

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const handleAuthorizeUser = createAsyncThunk(
//   "auth/autherizeUserAsync",
//   async (authToken : string | undefined) => {
    
//     const response = await authorizeUserAsync()
//     // const response = await instance.request()
//     // The value we return becomes the `fulfilled` action payload
//     return response
//   }
// )

export const loginToRemoteResource = createAsyncThunk(
  "user/loginToRemoteResource",
  async ({apiToken}:{apiToken: string | undefined}) => {
    const loginRequest = {
      method: 'POST',
      url: 'users/login',
      headers:{
        Authorization: `bearer ${apiToken}`
      }
    }

    const response = await instance.request(loginRequest)

    return response.data
  },
)


export const submitOnboardingForm = createAsyncThunk(
  "user/submitOnboardingForm",
  async ({onboarding, userId, authzyUserId}:
    {onboarding : OnboardingSelections, userId: number, authzyUserId: number}) => {

    const onboardingRequest = {
      method: 'POST',
      url: 'profiles/trainees',
      data:{
        userId : userId ,
        authzyUserId : authzyUserId,        
        pathwayId: onboarding.pathway,  
        supervisor: {
          name: onboarding.supervisor.firstName + " " + onboarding.supervisor.lastName,
          bacbAccountId: onboarding.supervisor.bcbaAccountId,
          certificationNumber: onboarding.supervisor.acbCertificationNumber,
          email: onboarding.supervisor.email,
          dateQualifiedToSupervise: onboarding.supervisor.dateQualifiedToSupervise,
          qualificationTypeId: onboarding.supervisor.qualifications         
        },
        role: onboarding.role,
        contractDate: onboarding.supervisor.dateOfSupervisionContract,
      }
    }

    const response = await instance.request(onboardingRequest)

    return  response.data 
  },
)

export interface OnboardingSelections {
  pathway: Pathways,
  role: Roles,
  supervisor: Supervisor 
}

const defaultSupervisor:Supervisor = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  bcbaAccountId: "",
  acbCertificationNumber: "",
  qualifications: Qualifications.Unknown,
  dateQualifiedToSupervise: "",
  dateOfSupervisionContract: "",
  attachments: null
}

export const onboardingState : OnboardingSelections = {
  pathway: Pathways.Unknown,
  role: Roles.Unknown,
  supervisor: defaultSupervisor
}




export interface UserState {
  id: number | undefined,
  authzyUserId: number | undefined,
  isSupervisor: boolean,
  isTraineee: boolean,
  onboarding: OnboardingSelections,
  status: 'idle' | 'loading' | 'success' | 'failed',
  role: Roles,
  mySupervisors: Supervisor[],
  isOnboarded: boolean
}

const initialState:UserState = {
  id: undefined,
  authzyUserId: undefined,
  isSupervisor: false,
  isTraineee: false,
  onboarding: onboardingState,
  status: 'idle',
  role: Roles.Unknown,
  mySupervisors: [],
  isOnboarded: false
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
    setPathway(state, action: PayloadAction<Pathways>) {
      state.onboarding.pathway = action.payload
    },
    setRole(state, action: PayloadAction<Roles>) {
      state.onboarding.role = action.payload
    },
    setSupervisorFirstName(state, action: PayloadAction<string>) {
      state.onboarding.supervisor.firstName = action.payload
    },
    setSupervisorLastName(state, action: PayloadAction<string>) {
      state.onboarding.supervisor.lastName = action.payload
    },
    setSupervisorBcbaAccountId(state, action: PayloadAction<string>) {
      state.onboarding.supervisor.bcbaAccountId = action.payload
    },
    setSupervisorAcbCertNumber(state, action: PayloadAction<string>) {
      state.onboarding.supervisor.acbCertificationNumber = action.payload
    },
    setDateOfSupervisionContract(state, action: PayloadAction<string>) {
      state.onboarding.supervisor.dateOfSupervisionContract = action.payload
    },
    setDateQualifiedToSupervise(state, action: PayloadAction<string>) {
      state.onboarding.supervisor.dateQualifiedToSupervise = action.payload
    },
    setQualifications(state, action: PayloadAction<Qualifications>) {
      state.onboarding.supervisor.qualifications = action.payload
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(submitOnboardingForm.pending, (state) =>{
        state.status = "loading"
      })
      .addCase(submitOnboardingForm.fulfilled, (state, action) => {
        state.status = "idle"
        state.role = action.payload.role
        state.isOnboarded = true
        
        if( action.payload.supervisor && action.payload.supervisor !== defaultSupervisor){  
          state.mySupervisors = [...state.mySupervisors,  action.payload.supervisor]
        }
      })
      .addCase(submitOnboardingForm.rejected, (state) => {
        state.status = "failed"
      })
      .addCase(loginToRemoteResource.pending, (state) =>{
        state.status = "loading"
      })
      .addCase(loginToRemoteResource.fulfilled, (state, action) => {
        state.status = "idle"
        state.id = action.payload.id
        state.authzyUserId = action.payload.authzyUserId
        
      })
      .addCase(loginToRemoteResource.rejected, (state) => {
        state.status = "failed"
      })


      
  },
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.counter.value
export const selectPathway = (state: RootState) => state.user.onboarding.pathway
export const selectRole = (state: RootState) => state.user.onboarding.role
export const selectOnboardingSupervisor = (state: RootState) => state.user.onboarding.supervisor
export const selectIsOnboarded = (state:RootState) => state.user.isOnboarded
export const selectUserId = (state:RootState) => state.user.id
export const selectName = (state:RootState) => state.user.onboarding.supervisor.firstName + " " + state.user.onboarding.supervisor.lastName
export const selectBacbAccountId = (state:RootState) => state.user.onboarding.supervisor.bcbaAccountId
export const selectCertificationNumber = (state:RootState) => state.user.onboarding.supervisor.acbCertificationNumber
export const selectEmail = (state:RootState) => state.user.onboarding.supervisor.email
export const selectDateQualifiedToSupervise = (state:RootState) => state.user.onboarding.supervisor.dateQualifiedToSupervise
export const selectQualificationTypeId = (state:RootState) => state.user.onboarding.supervisor.qualifications 
export const selectAuthzyUserId = (state:RootState) => state.user.authzyUserId
export const selectOnboardingSelections = (state:RootState) => state.user.onboarding
export const selectApiToken = (state:RootState) => state.auth.apiToken


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }

export const selectAuth = (state:RootState) => state.auth
export default userSlice.reducer
