import React, { useState, useEffect, useCallback } from "react"
import {
    Table,
    TableCell,
    TableRow,
    TableBody, 
    TableContainer, 
    Checkbox,
    Grid,
    Tooltip, 
    TableSortLabel, 
    Box, 
    Input
} from '@mui/material'
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {Demodal} from 'demodal'
import { FieldworkEntry, FieldworkSupervisionType, LoggedHoursType, SettingType } from "../fieldworkSlice"
import {styled} from "@mui/system"
import dayjs from "dayjs"


const StyledRow = styled('tr')(({theme}) => ({
  '&::nth-of-type(even)':{
    backgroundColor: "#cccccc",
    '&:hover':{
      backgroundColor: '#ffffff'
    }
  },
  '&::nth-of-type(odd)':{
    backgroundColor: "#dddddd",
    '&:hover':{
      backgroundColor: '#ffffff'
    }
  }

}))


export const FieldworkRow = React.memo(({entry}: {entry:FieldworkEntry}) => {
  const dispatch = useAppDispatch()

  const handleTrackToggle = ({id}:{id:number}) => {
      dispatch({type:"tokens/toggleTrackingForToken", payload:id})
  }

  
  return(
    <StyledRow key={entry.id} >
      <td >
      {entry.date?
        dayjs(entry.date).toDate().toDateString()
        :
        '-'
      }
      </td>

      <td>
      {entry.startTime?
        dayjs(entry.startTime).toDate().toLocaleTimeString()
        :
        '-'
      }
      </td>

      <td>
      {entry.endTime?
        dayjs(entry.endTime).toDate().toLocaleTimeString()
        :
        '-'
      }
      </td>
      <td>
      {entry.supervisionType && entry.supervisionType !== FieldworkSupervisionType.Unknown ?
        FieldworkSupervisionType[entry.supervisionType].toString()
        :
        '-'
      }
      </td> 
      <td>
      {entry.setting && entry.setting !== SettingType.Unknown?
        SettingType[entry.setting].toString()
        :
        '-'
      }
      </td>
      <td>
      {entry.type && entry.type !== LoggedHoursType.Unknown?
        LoggedHoursType[entry.type].toString()
        :
        '-'
      }
      </td>
      <td>
      {entry.supervisorName?
        entry.supervisorName
        :
        '-'
      }
      </td>
    </StyledRow>
  )
})