import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import axios from "axios"
import { Qualifications } from "../common/commonTypes"


export interface Supervisor{
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  bcbaAccountId: string,
  acbCertificationNumber: string,
  qualifications: Qualifications,
  dateQualifiedToSupervise: string,
  dateOfSupervisionContract: string,
  attachments: File | null
}

export interface SupervisorState  {
  mySupervisors: Supervisor[],
  status: 'idle' | 'loading' | 'failed',
  // currentEntryId is only temporary for mocking the front end. 
  // Eventually the api will return a userId
  currentEntryId: number 

} 

const initialState : SupervisorState = {
  mySupervisors: [],
  status: 'idle',
  currentEntryId: 1,
}

export const addSupervisor = createAsyncThunk(
  "supervisor/addSupervisor",

  async ({ userId, supervisor }:{ userId: number, supervisor: Supervisor }) => {
  //   const response = await instance.request({
  //     method: 'POST',
  //     url:`/token/live`,
  //     data:{
  //       requestedSource: LaunchedTypes.All,
  //       fetchCount: NUM_LIVE_DATA_ENTRIES,
  //       id: id,
  //       nonce: nonce,
  //       chain: chain === ChainIds.Ethereum? 'eth':'solana'
  //     }
  //   })
  
      const response = {
          data: supervisor
          
      }
      return { data: response.data }
  },
)



export const removeSupervisor = createAsyncThunk(
  "fieldwork/addEntry",

  async ({ userId, supervisorId }:{ userId: number, supervisorId: number }) => {
  //   const response = await instance.request({
  //     method: 'POST',
  //     url:`/token/live`,
  //     data:{
  //       requestedSource: LaunchedTypes.All,
  //       fetchCount: NUM_LIVE_DATA_ENTRIES,
  //       id: id,
  //       nonce: nonce,
  //       chain: chain === ChainIds.Ethereum? 'eth':'solana'
  //     }
  //   })
  
      const response = {
          status: 200,
          data: {
              id: supervisorId
          }

          
      }
      return { data: response.data }
  },
)


export const supervisorSlice = createSlice({
  name: "supervisor",
  initialState,
  reducers:{
      
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSupervisor.pending, (state) => {
        state.status = "loading"
      })
      .addCase(addSupervisor.fulfilled, (state, action) => {
        state.status = "idle"
        state.mySupervisors = [{...action.payload.data, id: state.currentEntryId + 1}, ...state.mySupervisors]
        state.currentEntryId += 1;
      })
      .addCase(addSupervisor.rejected, (state) => {
        state.status = "failed"
      })
      .addCase(removeSupervisor.pending, (state) => {
        state.status = "loading"
      })
      .addCase(removeSupervisor.fulfilled, (state, action) => {
        state.status = "idle"
        state.mySupervisors = state.mySupervisors.filter((supervisor)=>supervisor.id !== action.payload.data.id)
      })
      .addCase(removeSupervisor.rejected, (state) => {
        state.status = "failed"
      })
  }
})

export default supervisorSlice.reducer