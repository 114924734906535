import React from 'react'
import { FieldworkRow } from '../features/fieldwork/components/FieldworkRow'
import { FieldworkTable } from '../features/fieldwork/components/FieldworkTable'
import { FieldworkEntry, selectFieldwork } from '../features/fieldwork/fieldworkSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { Box, Button } from '@mui/material'
import { Demodal } from 'demodal'
import { AddFieldworkModal } from '../features/fieldwork/components/AddFieldworkModal'





function Homepage() {
  const dispatch = useAppDispatch()
  const fieldworkEntries = useAppSelector( selectFieldwork)

  return (
    <Box sx={{mt:'5%'}}>
      <FieldworkTable data={fieldworkEntries} title='My Fieldwork' subtitle='Today' />
      <Box>
        <Button onClick={()=>{Demodal.open(AddFieldworkModal)}}>
          Add Entry
        </Button>
      </Box>
    </Box>
  )
}

export default Homepage