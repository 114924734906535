import * as React from 'react';
import { Box, IconButton, Typography, Container, Avatar, Button, AppBar, Toolbar, Menu, Tooltip, MenuItem, PopoverVirtualElement } from "@mui/material";
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react';
import { selectApiToken, selectAuth, selectIsOnboarded, selectUserId } from '../features/user/userSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import './navbar.css'

const pages = [ 'Dashboard', 'My Fieldwork', 'My Supervisors' ];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const auth = useAppSelector(selectAuth);
  const apiToken = useAppSelector(selectApiToken)
  const userId = useAppSelector(selectUserId)
  const isOnboarded = useAppSelector(selectIsOnboarded)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    await dispatch({type: 'auth/logout'})
    navigate("Login");
  }

  const settings = [
    {title: 'Profile', onSelectHandler: ()=>{} },
    {title: 'Account', onSelectHandler: ()=>{} },
    {title: 'Logout', onSelectHandler: ()=>{handleLogout()} }
  ];

  useEffect(()=> {
    if ( auth && apiToken ) {
      if(isOnboarded)
        navigate("Dashboard");
      else{
        navigate("Onboarding")
      }
    }
  },[auth, apiToken])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" elevation={5} sx={{pt: 5, pb: 3, bgcolor: '#202322', '&::-webkit-scrollbar': {display: 'none'}}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>    
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >           
            <Link to={`/`}>              
              <img src='' alt="Logo" />
            </Link>  
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              
            >
              <Avatar alt="Hamburger Icon" src='' />             
            </IconButton>
            <Box sx={{pt: 5}} />
              <Menu
                id="menu-appbar"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ root: {"& .MuiPaper-root": {overflowY: 'none'}, '&.MuiPopover-root': {overflowY: 'none'}} , overflowY:'hidden', bgcolor: 'black', width: '225px', 
                  opacity: '85%', height: '100vh', mt: '128px' , transition: 'transform .3s', 
                  display: { xs: 'block', md: 'none' }
                }}          
                MenuListProps={{
                  disablePadding: true,  
                  sx: { overflow: 'hidden' },                               
                }}
                style={{overflow: 'hidden'}}
                disablePortal
              >              
              {pages.map((page) => (
                  <MenuItem disableGutters key={page} onClick={handleCloseNavMenu} 
                    sx={{ p: '4px 4px 8px 8px', overflowY: 'hidden', overflow: 'hidden', color: 'white', bgcolor: 'black', '&:hover': { bgcolor: 'grey' } }}>                    
                      <Link to={`/${page}`} className='navLinks'>                      
                        {page}  
                      </Link>                      
                  </MenuItem>
              ))}
            </Menu>
          </Box>          
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
            <Link to={`/`}>
              <img src='' alt="Logo" />
            </Link>
          </Typography>
          {userId?  
          <Box sx={{ flexGrow: 1, ml: '65%' }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, transition: 'transform .3s', '&:hover': {transform: 'Scale(1.5)'} }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '50px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >              
              {settings.map((setting) => (
                <MenuItem key={setting.title} 
                onClick={() => {
                  handleCloseUserMenu(),
                  setting.onSelectHandler()
                }}>
                  <Typography textAlign="center">{setting.title}</Typography>
                </MenuItem>
              ))}
            </Menu> 
          </Box> : 
          <Box sx={{ flexGrow: 1, ml: '65%' }}>
            <Link to="Login" className='linkAttributes' >
              Login
            </Link>
          </Box>
          }
        </Toolbar>

        <Box sx={{overflow:'hidden', mx: 'auto', direction: 'column', alignItems: 'center', justifyContent: 'center', display: { xs: 'none', md: 'flex' }, '&:hover':{overflow:'hidden'}}}>
            {pages.map((page) => (
              <Button                
                key={page}
                onClick={handleCloseNavMenu}
                sx={{my: 2, mx: 3, color: 'white', display: 'block', transition: 'transform .3s', overflow:'hidden', '&:hover': { bgcolor: 'grey', overflow:'hidden'} }}
              >
                <Link style={{color: 'white', textDecoration: 'none', }} to={`/${page}`}>
                  {page}
                </Link>
              </Button>
            ))}
          </Box> 

      </Container>
    </AppBar>
  );
}
export default Navbar;