import { BrowserRouter as Router, Routes, Route, redirect, useNavigate, Navigate } from 'react-router-dom';
import Navbar from "./components/Navbar"

import "./App.css"
import Dashboard from './pages/Dashboard';
import MyFieldwork from './pages/MyFieldwork';
import MySupervisors from './pages/MySupervisors';
import { useAppSelector } from './app/hooks';
import { selectAuth } from './features/user/userSlice';
import { useEffect } from 'react';
import { Login } from 'secureid-react';
import { SecureIdConfiguration } from 'secureid-react';
import { useAppDispatch } from './app/hooks';
import UserLogin from './pages/UserLogin';
import { ProtectedRoute } from './components/ProtectedRoute';
import Homepage from './pages/Homepage';
import Onboarding from './pages/Onboarding';
import { Demodal } from 'demodal';
import { AuthServerUri, CorsOrigin, GoogleClientId, GoogleLoginUri, ResourceServerUri } from './config';

function App() {
  
  const auth = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()

  useEffect(()=>{
    const config: SecureIdConfiguration = {
      CorsOrigin: CorsOrigin, 
      AuthServerUri: AuthServerUri,
      ResourceServerUri: ResourceServerUri,
      GoogleClientId: GoogleClientId, 
      GoogleLoginUri: GoogleLoginUri 
    }

    dispatch({
      type:'auth/configureAuthzyEndpoints', 
      payload:config
    })
  },[])

  return (
    <div className="App">
      <Router>
      <Navbar />
        <Routes>     
          <Route path="Onboarding" element={<Onboarding />} />   
          <Route path="/" element={<Homepage />} />
          <Route path="Login" element={<UserLogin />} />
          <Route element={<ProtectedRoute/>} >
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="My Fieldwork" element={<MyFieldwork />} />
            <Route path="My Supervisors" element={<MySupervisors />} />          
          </Route>
        </Routes>
        {/* <Footer /> */}
      </Router>
      <Demodal.Container/>
    </div>
  )
}

export default App
