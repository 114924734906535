import React from "react";
import { Box, Button, Grid, LinearProgress, LinearProgressProps, Typography } from "@mui/material"
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Demodal } from 'demodal';
import { RequiredInformation } from '../requiredInformation';
import DateModal from "../components/modals/DateModal";
import { getDateFromDateSections } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { Link } from "react-router-dom";
import MyFieldwork from "./MyFieldwork";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

function Dashboard() {

  const handleClick = () => {
    
  }
    const [progress, setProgress] = React.useState(10);
    const [value, setValue] = React.useState<Dayjs | null>(dayjs(Date()));


    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //       setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //       clearInterval(timer);
    //     };
    //   }, []);  

  return (
    <Box sx={{ mx: ''}}>
      <Box sx={{ maxWidth: { md: '900px', lg: '1250px'}, height: { md: '900px', lg: '945px'}, bgcolor: 'lightgrey', borderRadius: '10px', p: '5px', mx: 'auto', mt: '25px' }}>
          <Grid container spacing={2} sx={{ maxWidth: { md: '800px', lg:'1150px'}, bgcolor: 'grey', borderRadius: '10px', p: '5px', mx: 'auto', mt: '35px' }}>
              <Box sx={{ display: 'flex', flexGrow: '1' }}>
                  <Grid item xs={5} sx={{ mx: 'auto', mt: '100px' }}>
                      <Typography sx={{ fontSize: '35px' }}>
                          Progress
                      </Typography>
                      <LinearProgressWithLabel sx={{ height: '20px', width: '100%', mt: '15px' }} value={progress} />
                  </Grid>
                  <Grid item xs={5} sx={{ minWidth: '450px', height: '250px', bgcolor: 'lightgrey', borderRadius: '10px', p: '5px', mx: 'auto', mt: '30px', mb: '30px' }}>
                      <Typography sx={{ mx: 'auto', color: 'black', fontSize: '30px' }}>Requirements</Typography>
                      <Box component='button' sx={{ display: 'flex', p: '5px', m: '5px', bgcolor: 'transparent', border: 'none', '&:hover': { bgcolor: '#00000030', cursor: 'pointer' }}}>
                          <Box sx={{ p: '8px', width: '8px', height: '8px', border: '3px solid black', mx: 'auto', ml: '25px' }} />
                          <Typography sx={{ mx: 'auto', ml: '5px' }}>Degree</Typography>
                      </Box>
                      <Box component='button' sx={{ display: 'flex', p: '5px', m: '5px', bgcolor: 'transparent', border: 'none', '&:hover': { bgcolor: '#00000030', cursor: 'pointer' }}}>
                          <Box sx={{ p: '8px', width: '8px', height: '8px', border: '3px solid black', mx: 'auto', ml: '25px' }} />
                          <Typography sx={{ mx: 'auto', ml: '5px' }}>Certificate</Typography>
                      </Box>
                      <Box component='button' sx={{ display: 'flex', p: '5px', m: '5px', bgcolor: 'transparent', border: 'none', '&:hover': { bgcolor: '#00000030', cursor: 'pointer' }}}>
                          <Box sx={{ p: '8px', width: '8px', height: '8px', border: '3px solid black', mx: 'auto', ml: '25px' }} />
                          <Typography sx={{ mx: 'auto', ml: '5px' }}>License</Typography>
                      </Box>       
                      <Button variant="contained" sx={{ mx: 'auto'}}>See More Items</Button>             
                  </Grid>
              </Box>
          </Grid>
          <Grid container spacing={2} sx={{ maxWidth: { md: '800px', lg:'1150px'}, bgcolor: 'grey', borderRadius: '10px', p: '5px', mx: 'auto', mt: '32px', pb: '55px' }}>
              <Box sx={{ display: 'flex', flexGrow: '1' }}>
                  <Grid item xs={5} sx={{ mx: 'auto', mt: '20px' }}>
                      <Typography sx={{ fontSize: '35px' }}>
                          Choose date:
                      </Typography>
                      <Box sx={{ bgcolor: 'lightgrey', borderRadius: '15px', pt: '22.5px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DateCalendar']} sx={{ p: '10px', mx: '10%' }} >
                          <DemoItem>
                            <DateCalendar value={console.log(value)} onChange={(newValue) => setValue(newValue)} />                            
                            <Button onClick={(value)=>handleClick()}><Link to='MyFieldwork'>See Entries</Link></Button>
                          </DemoItem>                         
                          </DemoContainer>
                        </LocalizationProvider>
                      </Box>
                  </Grid>
                  <Grid item xs={5} sx={{ minWidth: '450px', height: '250px', bgcolor: 'lightgrey', borderRadius: '10px', p: '5px', mx: 'auto', mt: '30px', mb: '30px' }}>
                      <Typography sx={{ mx: 'auto', color: 'black', fontSize: '30px' }}>This Months Todo's</Typography>
                      <Box component='button' sx={{ display: 'flex', p: '5px', m: '5px', bgcolor: 'transparent', border: 'none', '&:hover': { bgcolor: '#00000030', cursor: 'pointer' }}}>
                          <Box sx={{ p: '8px', width: '8px', height: '8px', border: '3px solid black', mx: 'auto', ml: '25px' }} />
                          <Typography sx={{ mx: 'auto', ml: '5px' }}>Sign Form</Typography>
                      </Box>
                      <Box component='button' sx={{ display: 'flex', p: '5px', m: '5px', bgcolor: 'transparent', border: 'none', '&:hover': { bgcolor: '#00000030', cursor: 'pointer' }}}>
                          <Box sx={{ p: '8px', width: '8px', height: '8px', border: '3px solid black', mx: 'auto', ml: '25px' }} />
                          <Typography sx={{ mx: 'auto', ml: '5px' }}>Get approval from supervisor (name)</Typography>
                      </Box>
                      <Box component='button' sx={{ display: 'flex', p: '5px', m: '5px', bgcolor: 'transparent', border: 'none', '&:hover': { bgcolor: '#00000030', cursor: 'pointer' }}}>
                          <Box sx={{ p: '8px', width: '8px', height: '8px', border: '3px solid black', mx: 'auto', ml: '25px' }} />
                          <Typography sx={{ mx: 'auto', ml: '5px' }}>Get approval from supervisor (name)</Typography>
                      </Box>       
                      <Button variant="contained" sx={{ mx: 'auto'}}>See More Items</Button>             
                  </Grid>
              </Box>
          </Grid>
      </Box>
    </Box>
  )
}

export default Dashboard