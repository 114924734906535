import { useEffect } from "react";
import { Login, selectApiToken } from "secureid-react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { loginToRemoteResource } from "../features/user/userSlice";
import Logo from 'secureid-react/dist/logo-G6BYFCJT.png'

function UserLogin() {

  const apiToken = useAppSelector(selectApiToken)
  const dispatch = useAppDispatch()

  useEffect(()=>{
    if(apiToken && apiToken !== ''){
      dispatch(loginToRemoteResource({apiToken:apiToken}))
    }

  },[apiToken])

  return (
      <Login innerPadding="0px" logo={Logo} />
  )
}

export default UserLogin